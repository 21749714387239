import { SalesOrganisationConfig } from '../types'

export const salesOrganisationConfig: SalesOrganisationConfig = {
    DE01: {
        email: {
            csr: 'connect@brenntag.de',
        },
        phone: {
            csr: '+49 201 6496 1999',
        },
        bcc: {
            csrs: [],
            users: ['customers.de@digib.com'],
        },
    },
    BE01: {
        email: {
            csr: 'brenntagconnect.be@brenntag.com',
        },
        phone: {
            csr: '+32 (0)56 78 84 11',
        },
        bcc: {
            csrs: [],
            users: ['customers.be@digib.com'],
        },
    },
    NL01: {
        email: {
            csr: 'brenntagconnect.nl@brenntag.com',
        },
        phone: {
            csr: '+31 (0)78 65 44 999',
        },
        bcc: {
            csrs: [],
            users: ['customers.nl@digib.com'],
        },
    },
    A100: {
        email: {
            csr: 'connect@brenntag.pl',
        },
        phone: {
            csr: '+48 22 544 78 70',
        },
        bcc: {
            csrs: [],
            users: ['customers.pl@digib.com'],
        },
    },
}
