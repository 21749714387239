import { SettingsRegion } from '../types'
import { bnlConfig } from './config.bnl'

const config: SettingsRegion = {
    ...bnlConfig,
    privacyPolicyUrl: {
        nl: 'https://www.brenntag.com/nl-be/privacy-reglement.html',
        fr: 'https://www.brenntag.com/fr-be/politique-de-confidentialite.html',
        en: 'https://www.brenntag.com/en-be/privacy-policy.html',
    },
    imprintUrl: {
        nl: 'https://www.brenntag.com/nl-be/imprint.html',
        fr: 'https://www.brenntag.com/fr-be/imprimer.html',
        en: 'https://www.brenntag.com/en-be/imprint.html',
    },
    faqUrl: {
        nl: 'https://www.brenntag.com/nl-be/services/brenntag-connect-online-services/faq/',
        fr: 'https://www.brenntag.com/fr-be/services/services-en-ligne-brenntag-connect/faq/',
        en: 'https://www.brenntag.com/en-be/services/brenntag-connect-online-services/faq/',
    },
    tacUrl: {
        nl: 'https://www.brenntag.com/nl-be/algemene-voorwaarden.html',
        fr: 'https://www.brenntag.com/fr-be/conditions-generales.html',
        en: 'https://www.brenntag.com/en-be/terms-and-conditions.html',
    },
    email: {
        generic: 'brenntagconnect.be@brenntag.com',
        csr: 'brenntagconnect.be@brenntag.com',
        gdpo: 'gdpo@brenntag.de',
    },
    phone: {
        csr: '+32 (0)56 78 84 11',
    },
    officeHours: '8:00 - 12:00, 13:00 - 17:00',
    taxes: [
        {
            percentage: 21,
            since: '01-01-2000',
        },
    ],
}

export default config
