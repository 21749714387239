import { SettingsRegion } from '../types'
import { ceeConfig } from './config.cee'

const config: SettingsRegion = {
    ...ceeConfig,
    currency: 'PLN',
    currencyDisplay: 'code',
    dateFormat: 'DD.MM.YYYY',
    footerCopyrightLink: 'http://www.brenntag.pl/',
    privacyPolicyUrl: {
        en: 'https://www.brenntag.com/en-pl/privacy-policy.html',
        pl: 'https://www.brenntag.com/pl-pl/polityka-prywatnosci.html',
    },
    imprintUrl: {
        en: 'https://www.brenntag.com/en-pl/imprint.html',
        pl: 'https://www.brenntag.com/pl-pl/dane-firmy.html',
    },
    faqUrl: {
        en: 'https://www.brenntag.com/en-pl/services/brenntag-connect-online-services/faq/',
        pl: 'https://www.brenntag.com/pl-pl/uslugi/brenntag-connect-portal-klienta/faq/',
    },
    tacUrl: {
        en: 'https://www.brenntag.com/en-pl/terms-and-conditions.html',
        pl: 'https://www.brenntag.com/pl-pl/warunki-wspolpracy.html',
    },
    csrConfirmationEmail: 'info@brenntagconnect.pl',
    email: {
        generic: 'pl.connect@brenntag.com',
        csr: 'pl.connect@brenntag.com',
        gdpo: 'bodo@brenntag.pl',
        from: 'pl.connect@brenntag.com',
    },
    phone: {
        csr: '+48 22 544 78 70',
    },
    officeHours: '8:00 - 16:00',
    taxes: [
        {
            percentage: 23,
            since: '01-01-2000',
        },
    ],
}

export default config
